/** @format */

import React from "react";
import { SiMedium } from "react-icons/si";
import {
  FaReddit,
  FaGithub,
  FaTwitter,
  FaTelegram,
  FaBook,
  FaInstagram, 
  FaFacebook,
  FaDiscord,
} from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import LangSelect from "../utils/LangSelect";


function Footer() {
  const [t,i18n] = useTranslation();

  return (
    <div className='container-fluid footer w-100'>
      <div className='container mt-5 pt-5 text-center'>
        <div className='row'>
          <div className='col-md-12'>
            <img
              src={"assets/logo.png"}
              className='w-25 d-block  m-auto'
              alt=''
            />{" "}
            <br />
            <div className='flex justify-center flex-wrap'>
              <a rel='noreferrer' href='/whitepaper.pdf' target='_blank'>
                {" "}
                <FaBook className='mx-2 ' />
              </a>
              <a href='https://medium.com/@dingadangio' target='_blank' rel='noopener noreferrer'>
                {" "}
                <SiMedium className='mx-2' />
              </a>
              <a href='https://twitter.com/kiktoken' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaTwitter  className='mx-2' />
              </a>
              <a href='https://t.me/Dingdang_io' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaTelegram className='mx-2' />
              </a>
              <a href='https://discord.gg/gMZfvuhS' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaDiscord className='mx-2' />
              </a>
              <a href='https://www.reddit.com/r/Dingdangio/' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaReddit className='mx-2' />
              </a>
              <a href='https://github.com/Dingdangio' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaGithub className='mx-2' />
              </a>
              {/* <a href='' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaInstagram className='mx-2' />
              </a> */}
            
            </div>
          {/* <LangSelect className='text-light m-auto mt-4' /> */}

            <hr />
            <p>{t('© Copyright 2024 - All Rights Reserved - Ding Dang')} </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
