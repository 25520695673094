/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const NftsSection = () => {
  const [t,i18n] = useTranslation();

  return (
      <>
    <div className='container-fluid mt-5 nfts'>
          <div className='container-fluid banner'>
        <div className=' d-flex justify-content-center' id='banner'>
            <div className='banner-content text-center' id='scene'>
              <div className='banner-pig-images d-flex justify-content-center align-items-end'>
                <div className='banner-pig-1'>
                  <img
                    src='assets/8.png'
                    alt='pig-images'
                    className='img-fluid layer w-50'
                    data-depth='0.1'
                  />
                </div>
                <div className='banner-pig-2'>
                  <img
                    src='assets/1.png'
                    alt='pig-images'
                    className='img-fluid layer w-50'
                    data-depth='0.2'
                  />
                </div>
                
                <div className='banner-pig-3'>
                  <img
                    src='assets/3.png'
                    alt='pig-images'
                    className='img-fluid layer'
                    data-depth='0.1'
                  />
                </div>
              </div>
              <h1>{t('Ding Dang NFTs')}</h1>
              <h3>{t('Own a Unique Ding Dang NFTs')}</h3>
              <h6>
              {t('Claim your exclusive slice of the Ding Dang with Meow NFTs')}  
              </h6>
              <p className='w-75 m-auto mb-4 mt-4'>
              {t('Crafted with care by our team and the vibrant community, these NFTs within the Ding Dang encompass a diverse range, including characters, props, and valuable items. For newcomers, we extend a warm welcome with a complimentary starter pack, allowing you to dive right in, play, and commence your journey to earning in the Jungle Twist universe!')} 
              </p>
              <a href='#' className='btn-style'>
              {t('View on Opensea')} 
              </a>
            </div>
          </div>
        </div>
    </div>
      </>
  );
};

export default NftsSection;
