/** @format */

import React, { useEffect } from "react";
import LangSelect from "../utils/LangSelect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Header() {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".navbar");
      if (window.pageYOffset > 85) {
        header.classList.add("navbar-fixed-top");
      } else {
        header.classList.remove("navbar-fixed-top");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <nav className='navbar navbar-expand-lg '>
        <div className='container '>
          <a className='navbar-brand' href='/'>
            <img src={"assets/logo-cr.png"} alt='' width={80} />
          </a>
          {/* <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button> */}
          {/* <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a
                  className='nav-link active '
                  target='_blank'
                  rel='noreferrer'
                  href=''>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link '
                  target='_blank'
                  rel='noreferrer'
                  href='/'>
                  Contract
                </a>
              </li>
              <li>
                <a
                  className='nav-link'
                  target='_blank'
                  rel='noreferrer'
                  href=''>
                  Price Chart
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className='nav-link'
                  href=''
                  rel='noreferrer'
                  target='_blank'>
                  Dextool
                </a>
              </li>
            </ul>
          </div> */}
          <div className='flex '>
            {/* <a  rel="noreferrer" target="_blank" href="">
              <button className='nav-btns mx-4'> {t("Airdrop")} </button>
            </a> */}
            {/* <a href='' target='_blank' rel='noopener noreferrer'>
            <button className='nav-btns mx-4'> {t('Get $Meow')} </button>
          </a> */}
            <LangSelect className='' />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
