/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Section2 = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container section1 '>
      <div className='sec-box'>
        <div className='row row-re'>
          <div className='col-md-6 m-auto'>
            <h6>{t('Accumulate $Meow tokens while enjoying your beloved game.')}</h6>
            <p>
            {t("Ding dang Jungle Twist game collection lies a treasure trove of timeless and casual games that you've grown to cherish, all featuring CAT as the central character. These games within the collection form an interconnected on-chain system, enabling users to earn and utilize the same $Meow token seamlessly across every game, including all new additions.")}   
            </p>
            <p>
            {t("What's more, the magic doesn't stop there. When you unlock a character or collectible as an NFT in one game, its counterpart automatically unlocks in all the other games within the collection, creating a thrilling and unified gaming experience like no other.")}  
            </p>
          </div>
          <div className='col-md-6'>
            <img
                   src='assets/5.png'
                   className='w-75 m-auto d-block '
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
