/** @format */

import React from "react";
import {
  FaReddit,
  FaGithub,
  FaTwitter,
  FaTelegram,
  FaBook,
  FaInstagram,
  FaFacebook,
  FaDiscord,
} from "react-icons/fa";

import { useTranslation } from "react-i18next";
import { SiMedium } from "react-icons/si";
import { Button, Dropdown } from "antd";
import ReactCountryFlag from "react-country-flag";
import { useState } from "react";
import CountDown from "../utils/CountDown";
const Hero = props => {
  const data = [
    { lang: "English", code: "GB", link: "/whitepaper.pdf" },
    { lang: "Chinese", code: "CN", link: "/whitepaper_ch.pdf" },
  ];
  const [langData, setData] = useState(data);

  const [t, i18n] = useTranslation();
  const menu = (
    <div className='bg-white shadow px-2 py-2 rounded-lg '>
      {langData.map((item, idx) => (
        <a
          href={item.link}
          target='_blank'
          rel='noreferrer'
          className='flex justify-between items-center w-full mt-2 grayButton rounded-md p-2'
          key={idx}>
          <span className=' font-bold'>{item.lang} </span> &nbsp;
          <ReactCountryFlag
            className='rounded-full '
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            countryCode={item.code}
            svg
            cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
            cdnSuffix='svg'
            title='US'
          />
        </a>
      ))}
    </div>
  );
  return (
    <section className='hero-area heroV1'>
      <div className='hv1-box pt-5'>
        <div className='container'>
          <div className='hero-content'>
            <div className='row'>
              <div className='col-md-6 m-auto '>
                <div className='hero-text'>
                  <h2>
                    {t(
                      "Ding dang is not another meme token, it thrives on genuine utility",
                    )}{" "}
                  </h2>
                  <p>
                    {t(
                      "A Symbiotic Harmony of Memes, Gaming and Staking Wealth",
                    )}
                  </p>
                  {/* <CountDown /> */}
                  <div className='flex'>
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href='https://pancakeswap.finance/swap?outputCurrency=0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e'
                      className='btn-style text-center b aan w-50'>
                      {t("Buy on PancakeSwap")}
                    </a>{" "}
                    &nbsp;
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href='https://pool.dingdang.io/'
                      className='btn-style text-center b  w-05'>
                      {t("$Meow Staking")}
                    </a>{" "}
                  </div>

                  <div className='hero-btn'>
                    <Dropdown
                      overlay={menu}
                      placement='bottomLeft'
                      className={`${props.className} `}>
                      <button className='btn-style a '>Whitepaper</button>
                    </Dropdown>
                    <a
                      href='https://blocksafu.com/audit/0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e'
                      rel='noreferrer'
                      target='_BLANK'
                      className='btn-style b'>
                      {t("Audits")}
                    </a>{" "}
                    &nbsp;
                    <a
                      href='https://t.me/Dingdang_io'
                      rel='noreferrer'
                      target='_BLANK'
                      className='btn-style b mt-3'>
                      {t("Join us")}
                    </a>
                  </div>
                  <div className='hero-icons m-auto mt-5'>
                    <a
                      href='/whitepaper.pdf'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaBook />
                    </a>
                    <a
                      href='https://medium.com/@dingadangio'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <SiMedium />
                    </a>
                    <a
                      href='https://twitter.com/kiktoken'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaTwitter />
                    </a>
                    <a
                      href='https://t.me/Dingdang_io'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaTelegram />
                    </a>
                    <a
                      href='https://www.reddit.com/r/Dingdangio/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaReddit />
                    </a>
                    <a
                      href='https://github.com/Dingdangio'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaGithub />
                    </a>
                    {/* <a href='#!' target='_blank' rel='noopener noreferrer'>
                      {" "}
                      <FaInstagram />
                    </a> */}
                    <a
                      href='https://discord.gg/gMZfvuhS'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <FaDiscord />
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-6 m-auto '>
                <div className='hero-img'>
                  <img src='assets/logo.png' alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
