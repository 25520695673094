/** @format */

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from 'react-i18next';

const HowToGet = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className=' container-fluid mt-5 how-to-get'>
      <div className='section-heading'>
        <div className='accent-large'>{t('Add')}</div>
        <h2 className='sec-title'>{t('How to get $Meow')}</h2>
      </div>

      <div className='m-auto'>
    
        <Tabs
          id='controlled-tab-example'
          defaultActiveKey='one'
          style={{ maxWidth: "400px" }}
          className='mb-3 mt-5  m-auto'>
          <Tab
            eventKey='one'
            title={t('STEP 1')}
            className='m-auto mt-5'
            style={{ maxWidth: "720px" }}>
            <h3>{t('Create a MetaMask Wallet')}</h3>
            <p>
            {t('$Meow token is available on the Binance smart chain. MetaMask is a third party BEP20 (BSC) browser wallet, and the very best at that! On Google Chrome, visit metamask.io to download the extension and set up a wallet. On mobile? Get')}   {" "}
              <strong>{t('MetaMask')}</strong>'{t('s app for')}
              <strong> {t('iPhone')}</strong> {t('or')}  <strong>{t('Android')}</strong>.
            </p>
          </Tab>
          <Tab
            eventKey='two'
            title={t('STEP 2')}

            className='m-auto mt-5'
            style={{ maxWidth: "720px" }}>
            <h3>{t('Send $BNB to MetaMask')}</h3>
            <p>
            {t('Acquire BNB through MetaMask itself or transfer it to your MetaMask wallet address from another wallet (e.g. Coinbase or Binance).')} 
            </p>{" "}
          </Tab>
          <Tab
            eventKey='three'
            title={t('STEP 3')}
            className='m-auto mt-5'
            style={{ maxWidth: "720px" }}>
            <h3>{t('Visit PancakeSwap')}</h3>
            <p>
            {t('You can currently swap BNB and other tokens for $Meow on PancakeSwap')}
            </p>
          </Tab>
          <Tab
            eventKey='four'
            title={t('STEP 4')}
            className='m-auto mt-5'
            style={{ maxWidth: "720px" }}>
            <h3>{t('Swap $BNB for $Meow')}</h3>
            <p>
            {t('Enter the amount of $BNB you would like to swap for $Meow. Click Connect Wallet then Swap')} 
            </p>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default HowToGet;
