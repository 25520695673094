/** @format */

import "./App.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./component/layout/Base";
import ReactGA from "react-ga";
import Token from "./pages/Token";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, bsc } from "wagmi/chains";
ReactGA.initialize("G-7BY5JMJ227");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const chains = [arbitrum, mainnet, polygon, bsc];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <BrowserRouter>
        <WagmiConfig config={wagmiConfig}>
          <Base>
            <Routes>
              <Route index path='/' element={<Home />} />
              {/* <Route path='/airdrop' element={<Token />} /> */}
            </Routes>
          </Base>
        </WagmiConfig>
        <div className='z-[9999] relative'>
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </div>{" "}
      </BrowserRouter>
    </>
  );
}

export default App;
