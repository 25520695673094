/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Staking = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container-fluid stake'>
      <div className='container'>
        <div className='section-heading stake-img'>
          <div className='accent-large'>{t('Stake')}</div>
        </div>
        <div className='sec-box '>
          <div className='row'>
            <div className='col-md-6 m-auto'>
              <h3>{t('Staking $Meow')}</h3>
              <h6>
              {t('Start staking $Meow today to unlock rewards and amplify your voting influence.')} {" "}
              </h6>
              <p>
              {t("The option to stake $Meow for rewards is now accessible, offering early adopters a bonus reward multiplier for staking their $Meow before the official launch. By staking $Meow, you not only earn more $Meow but also gain access to exclusive in-game and community collectibles (NFTs). Furthermore, the more $Meow you stake, the greater your influence in shaping the project's future through enhanced voting power.")} 
              </p>
            </div>
            <div className='col-md-6'>
              <img
                src='assets/9.png'
                className='w-100'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staking;
