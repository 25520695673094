/** @format */

import React from "react";
import { useTranslation } from "react-i18next";

const Partner = () => {
  const part = [
    {
      imgUrl: "assets/pinksale-1.svg",
      aUrl: "https://www.pinksale.finance/launchpad/0x054524986f7625535781940931bce950d96f2e4a?chain=BSC",
    },
    {
      imgUrl: "assets/dexview.svg",
      aUrl: "https://www.dexview.com/bsc/0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e",
    },
    {
      imgUrl: "assets/full-pancakeswap-logo.svg",
      aUrl: "https://pancakeswap.finance/swap?outputCurrency=0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e",
    },
    {
      imgUrl: "assets/full-coinmarketcap-logo.png",
    },
    {
      imgUrl: "assets/CoinGecko-logo.png",
    },
    {
      imgUrl: "assets/dextools-logo-B3AAF03695-seeklogo.com.png",
      aUrl: "https://www.dextools.io/app/en/bnb/pair-explorer/0x66e6c210df4aecb08b9fefea0033d94754327618"
    },
    {
      imgUrl: "assets/bscscan-logo.png",
      aUrl: "https://bscscan.com/token/0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e",
    },
    {
      imgUrl: "assets/blocksafu.svg",
      aUrl: "https://blocksafu.com/audit/0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e",
    },
    {
      imgUrl: "assets/Official_unity_logo.png",
    },
    // {
    //   imgUrl: "assets/PngItem_3439463.png",
    // },
    {
      imgUrl: "assets/bitmart.svg",
      active: "Upcoming",
    },
    {
      imgUrl: "assets/Bybit.png",

      active: "Upcoming",
    },
    {
      imgUrl: "assets/lbak.svg",

      active: "Upcoming",
    },
  ];
  const [t, i18n] = useTranslation();

  return (
    <div className=' container-fluid partner mt-5 pt-5'>
      <div className='section-heading is--buy'>
        <div className='accent-large left-justify'>{t("Partners")}</div>
      </div>

      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-5 m-auto'>
          <h5>{t("DingDang PARTNERS")}</h5>
          <h2>
            {t("Where to")} <br />
            {t("get $Meow")}
          </h2>
          <p>
            {t(
              "The $Meow token will be available on 20+ different exchanges. We continuously look for new partners to join us in offering you new ways to acquire $Meow.",
            )}
          </p>
        </div>
        <div className='col-md-6 m-auto'>
          <div className='row'>
            {part.map((v, i) => {
              return (
                <div className='col-sm-4 mt-3 col-6 ' key={i}>
                  <a
                    href={v.aUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='img-box relative'>
                    {v.active && (
                      <div class='absolute left-[0px] top-[0px] w-[80px] transform  rounded-full text-xs bg-gray-600 text-center text-white font-semibold py-1'>
                        {v.active}
                      </div>
                    )}
                    <img src={v.imgUrl} alt='' />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
