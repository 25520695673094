/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Section3 = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container section1 '>
      <div className='sec-box'>
        <div className='row'>
          <div className='col-md-6'>
            <img src='assets/4.png' className='w-75 m-auto d-block ' alt='' />
          </div>
          <div className='col-md-6 m-auto'>
            <h3>{t('Dangverse')}</h3>
            <h6>
            {t('Jungle Twist by Dingdong, delivering an unparalleled VR adventure.')} 
            </h6>
            <p>
            {t('After the launch of our initial Jungle Twist series, our dedicated team will embark on the exciting journey of developing the DangVerse—a groundbreaking VR metaverse where the NFTs acquired by our Jungle Twist game players will transcend into vibrant, living entities.')} 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
