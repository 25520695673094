/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      "Get $Meow": "Get $Meow",
      "© Copyright 2024 - All Rights Reserved - Ding Dang":
        "© Copyright 2024 - All Rights Reserved - Ding Dang",
      "Ding dang is not another meme token, it thrives on genuine utility":
        "Ding dang is not another meme token, it thrives on genuine utility",
      "A Symbiotic Harmony of Memes, Gaming and Staking Wealth":
        "A Symbiotic Harmony of Memes, Gaming and Staking Wealth",
      "Stake $Meow": "Stake $Meow",
      Whitepaper: "Whitepaper",
      "Join us": "Join us",
      Add: "Add",
      "How to get $Meow": "How to get $Meow",
      "Create a MetaMask Wallet": "Create a MetaMask Wallet",
      "$Meow token is available on the Binance smart chain. MetaMask is a third party BEP20 (BSC) browser wallet, and the very best at that! On Google Chrome, visit metamask.io to download the extension and set up a wallet. On mobile? Get":
        "$Meow token is available on the Binance smart chain. MetaMask is a third party BEP20 (BSC) browser wallet, and the very best at that! On Google Chrome, visit metamask.io to download the extension and set up a wallet. On mobile? Get",
      MetaMask: "MetaMask",
      "s app for": "s app for",
      or: "or",
      Android: "Android",
      "STEP 1": "STEP 1",
      "STEP 2": "STEP 2",
      "STEP 3": "STEP 3",
      "STEP 4": "STEP 4",
      "Send $BNB to MetaMask": "Send $BNB to MetaMask",
      "Acquire BNB through MetaMask itself or transfer it to your MetaMask wallet address from another wallet (e.g. Coinbase or Binance).":
        "Acquire BNB through MetaMask itself or transfer it to your MetaMask wallet address from another wallet (e.g. Coinbase or Binance).",
      "Visit PancakeSwap": "Visit PancakeSwap",
      "You can currently swap BNB and other tokens for $Meow on PancakeSwap":
        "You can currently swap BNB and other tokens for $Meow on PancakeSwap",
      "Swap $BNB for $Meow": "Swap $BNB for $Meow",
      "Enter the amount of $BNB you would like to swap for $Meow. Click Connect Wallet then Swap":
        "Enter the amount of $BNB you would like to swap for $Meow. Click Connect Wallet then Swap",
      Why: "Why",
      "Why you might want to consider investing in $Meow token":
        "Why you might want to consider investing in $Meow token",
      "Kikswap raised 506 BNB on presale launch in January 2022 with the marketcap over multi million dollar. Kik token price shoot over 10x on Pancakeswap listing. After 2 year team is back highly motivated with back up funds introducing you Ding Dang $Meow token. This is not just another meme token $Meow is a meme staking token introduced by KikSwap OG team. Stake any meme token and earn upto 1200% realistic APY in $Meow tokens.":
        "Kikswap raised 506 BNB on presale launch in January 2022 with the marketcap over multi million dollar. Kik token price shoot over 10x on Pancakeswap listing. After 2 year team is back highly motivated with back up funds introducing you Ding Dang $Meow token. This is not just another meme token $Meow is a meme staking token introduced by KikSwap OG team. Stake any meme token and earn upto 1200% realistic APY in $Meow tokens.",
      "Partner with us": "Partner with us",
      "Ding Dang NFTs": "Ding Dang NFTs",
      "Own a Unique Ding Dang NFTs": "Own a Unique Ding Dang NFTs",
      "Claim your exclusive slice of the Ding Dang with Meow NFTs":
        "Claim your exclusive slice of the Ding Dang with Meow NFTs",
      "Crafted with care by our team and the vibrant community, these NFTs within the Ding Dang encompass a diverse range, including characters, props, and valuable items. For newcomers, we extend a warm welcome with a complimentary starter pack, allowing you to dive right in, play, and commence your journey to earning in the Jungle Twist universe!":
        "Crafted with care by our team and the vibrant community, these NFTs within the Ding Dang encompass a diverse range, including characters, props, and valuable items. For newcomers, we extend a warm welcome with a complimentary starter pack, allowing you to dive right in, play, and commence your journey to earning in the Jungle Twist universe!Crafted with care by our team and the vibrant community, these NFTs within the Ding Dang encompass a diverse range, including characters, props, and valuable items. For newcomers, we extend a warm welcome with a complimentary starter pack, allowing you to dive right in, play, and commence your journey to earning in the Jungle Twist universe!",
      "View on Opensea": "View on Opensea",
      Partners: "Partners",
      "DingDang PARTNERS": "DingDang PARTNERS",
      "Where to": "Where to",
      "get $Meow": "get $Meow",
      "The $Meow token will be available on 20+ different exchanges. We continuously look for new partners to join us in offering you new ways to acquire $Meow.":
        "The $Meow token will be available on 20+ different exchanges. We continuously look for new partners to join us in offering you new ways to acquire $Meow.",
      $Meow: "$Meow",
      "Ding dang Presents": "Ding dang Presents",
      "Jungle Twist": "Jungle Twist",
      "In a cottage lived a curious cat named Dingdang. Dingdang unlike any other cat in the neighborhood, for she had a peculiar fascination with Meows.":
        "In a cottage lived a curious cat named Dingdang. Dingdang unlike any other cat in the neighborhood, for she had a peculiar fascination with Meows.",
      "Dingdang was captivated by Meows. She would chase them around the room, her nimble paws batting at the shimmering orbs, her tail swaying in delight. The more Meows she popped, the more she giggled, creating a whimsical symphony that filled the cottage.":
        "Dingdang was captivated by Meows. She would chase them around the room, her nimble paws batting at the shimmering orbs, her tail swaying in delight. The more Meows she popped, the more she giggled, creating a whimsical symphony that filled the cottage.",
      "Dingdang's love for Meows became legendary in the neighborhood. She continued to chase them with the same enchantment, bringing smiles and laughter wherever she went. And in the hearts of those who knew her, Dingdang became a reminder that sometimes, the simplest of joys can create the most magical moments in life, all you need to find happiness is a little Meow of joy.":
        "Dingdang's love for Meows became legendary in the neighborhood. She continued to chase them with the same enchantment, bringing smiles and laughter wherever she went. And in the hearts of those who knew her, Dingdang became a reminder that sometimes, the simplest of joys can create the most magical moments in life, all you need to find happiness is a little Meow of joy.",
      "Accumulate $Meow tokens while enjoying your beloved game.":
        "Accumulate $Meow tokens while enjoying your beloved game.",
      "Ding dang Jungle Twist game collection lies a treasure trove of timeless and casual games that you've grown to cherish, all featuring CAT as the central character. These games within the collection form an interconnected on-chain system, enabling users to earn and utilize the same $Meow token seamlessly across every game, including all new additions.":
        "Ding dang Jungle Twist game collection lies a treasure trove of timeless and casual games that you've grown to cherish, all featuring CAT as the central character. These games within the collection form an interconnected on-chain system, enabling users to earn and utilize the same $Meow token seamlessly across every game, including all new additions.",
      "What's more, the magic doesn't stop there. When you unlock a character or collectible as an NFT in one game, its counterpart automatically unlocks in all the other games within the collection, creating a thrilling and unified gaming experience like no other.":
        "What's more, the magic doesn't stop there. When you unlock a character or collectible as an NFT in one game, its counterpart automatically unlocks in all the other games within the collection, creating a thrilling and unified gaming experience like no other.",
      Dangverse: "Dangverse",
      "Jungle Twist by Dingdong, delivering an unparalleled VR adventure.":
        "Jungle Twist by Dingdong, delivering an unparalleled VR adventure.",
      "After the launch of our initial Jungle Twist series, our dedicated team will embark on the exciting journey of developing the DangVerse—a groundbreaking VR metaverse where the NFTs acquired by our Jungle Twist game players will transcend into vibrant, living entities.":
        "After the launch of our initial Jungle Twist series, our dedicated team will embark on the exciting journey of developing the DangVerse—a groundbreaking VR metaverse where the NFTs acquired by our Jungle Twist game players will transcend into vibrant, living entities.",
      Stake: "Stake",
      "Staking $Meow": "Staking $Meow",
      "Start staking $Meow today to unlock rewards and amplify your voting influence.":
        "Start staking $Meow today to unlock rewards and amplify your voting influence.",
      "The option to stake $Meow for rewards is now accessible, offering early adopters a bonus reward multiplier for staking their $Meow before the official launch. By staking $Meow, you not only earn more $Meow but also gain access to exclusive in-game and community collectibles (NFTs). Furthermore, the more $Meow you stake, the greater your influence in shaping the project's future through enhanced voting power.":
        "The option to stake $Meow for rewards is now accessible, offering early adopters a bonus reward multiplier for staking their $Meow before the official launch. By staking $Meow, you not only earn more $Meow but also gain access to exclusive in-game and community collectibles (NFTs). Furthermore, the more $Meow you stake, the greater your influence in shaping the project's future through enhanced voting power.",
      Stats: "Stats",
      "Tokenomics & Stats": "Tokenomics & Stats",
      "Ding Dang Token Allocation": "Ding Dang Token Allocation",
      "Initial Liquidity Allocation for DEX":
        "Initial Liquidity Allocation for DEX",
      "Presale Tokens Allocation": "Presale Tokens Allocation",
      "Staking Rewards": "Staking Rewards",
      "Advisory Board": "Advisory Board",
      Marketing: "Marketing",
      "Product Development & Team": "Product Development & Team",
      "Research & Development": "Research & Development",
      "Gaming Reward": "Gaming Reward",
      "Airdrop & Bounty Campaign": "Airdrop & Bounty Campaign",
      "3 Tier CEX liquidity (Lbank, Bitmart, Hotbit)":
        "3 Tier CEX liquidity (Lbank, Bitmart, Hotbit)",
      "1st Tier CEX liquidity (Binance, Huobi, Kucoin)":
        "1st Tier CEX liquidity (Binance, Huobi, Kucoin)",
      "Raised Funds Allocation": "Raised Funds Allocation",
      Liquidity: "Liquidity",
      "Resource Hiring": "Resource Hiring",
      "Game Engine For Creators": "Game Engine For Creators",
      "R&D Funds": "R&D Funds",
      "Strategic Partnerships": "Strategic Partnerships",
      "Game Reward": "Game Reward",
      Liftoff: "Liftoff",
      Roadmap: "Roadmap",
      "Q4 2024": "Q4 2024",
      "PHASE inprogress": "PHASE inprogress",
      Launch: "Launch",
      "Ding Dang Branding & Logo Designing":
        "Ding Dang Branding & Logo Designing",
      "Website Release 2.0": "Website Release 2.0",
      "Whitepaper, Gitbook": "Whitepaper, Gitbook",
      "Game Development Alpha": "Game Development Alpha",
      "Governance platform": "Governance platform",
      "Staking Platform": "Staking Platform",
      "Growth hacking": "Growth hacking",
      Tokenomics: "Tokenomics",
      "Private sale (vesting)": "Private sale (vesting)",
      "DEX liquidity": "DEX liquidity",
      "3 tier CEX listing (Lbank, Hotbit, Bitmart)":
        "3 tier CEX listing (Lbank, Hotbit, Bitmart)",
      "Multi-Chain bridge integration": "Multi-Chain bridge integration",
      "Bounty campaign": "Bounty campaign",
      "Q1. 2024": "Q1. 2024",
      "PHASE Pending": "PHASE Pending",
      Growth: "Growth",
      "Collaboration with Influencers, Content Creators":
        "Collaboration with Influencers, Content Creators",
      "Brand awareness campaign": "Brand awareness campaign",
      "Contest competition": "Contest competition",
      "Onboard Ambassadors": "Onboard Ambassadors",
      "Private investors (25% Vesting release)":
        "Private investors (25% Vesting release)",
      "Onboard Advisors": "Onboard Advisors",
      "Bounty reward distribution": "Bounty reward distribution",
      "Integrate EVM compatible Chains": "Integrate EVM compatible Chains",
      "Q2. 2024": "Q2. 2024",
      Expansion: "Expansion",
      "Reward system for community": "Reward system for community",
      "SDK for traditional games (beta)": "SDK for traditional games (beta)",
      "Viral gaming contest": "Viral gaming contest",
      "1.5M+ Mobile Game Downloads": "1.5M+ Mobile Game Downloads",
      "CEX listing (Binance, Huobi, Kucoin)":
        "CEX listing (Binance, Huobi, Kucoin)",
      "Hiring Industry leaders": "Hiring Industry leaders",
      "Gaming Hackathon": "Gaming Hackathon",
      "Dingdang Merch": "Dingdang Merch",
      "Improve Game UX": "Improve Game UX",
      "Strategic Partnership": "Strategic Partnership",
      "Organise gaming events & meetups": "Organise gaming events & meetups",
      "Participate in Pocket Games Events":
        "Participate in Pocket Games Events",
      "Join Gaming Conferences": "Join Gaming Conferences",
      "Collaboration with celebrities": "Collaboration with celebrities",
      "Q4. 2024": "Q4. 2024",
      Utility: "Utility",
      "Partnership with Gaming Engine (Unity3D, Unreal)":
        "Partnership with Gaming Engine (Unity3D, Unreal)",
      "Marketplace for content creators": "Marketplace for content creators",
      "Convert blockchain currency to fiat currency":
        "Convert blockchain currency to fiat currency",
      "Leaderboard for winners": "Leaderboard for winners",
      "On demand content dashboard": "On demand content dashboard",
      "Meow Futures Trading Bot": "Meow Futures Trading Bot",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 36 hours after their initial launch.":
        "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 36 hours after their initial launch.",
      "Compete tasks and get 1000 $Meow when you sign up!":
        "Compete tasks and get 1000 $Meow when you sign up!",
      "Invite code (Optional)": "Invite code (Optional)",
      "Enter invite code →": "Enter invite code →",
      "Connect wallet to claim": "Connect wallet to claim",
      Connect: "Connect",
      "Join Dingdang.io": "Join Dingdang.io",
      Telegram: "Telegram",
      "and get a verified role": "and get a verified role",
      Join: "Join",
      Discord: "Discord",
      "Complete tasks and claim": "Complete tasks and claim",
      Meow: "Meow",
      Claim: "Claim",
      "THE TOKEN POWERING THE MEMECONOMY": "THE TOKEN POWERING THE MEMECONOMY",
      "The governance and reward mechanism for the DingDang Ecosystem":
        "The governance and reward mechanism for the DingDang Ecosystem",
      "JOIN THE $Meow TOKEN AIRDROP": "JOIN THE $Meow TOKEN AIRDROP",
      "How to earn Meow": "How to earn Meow",
      "$Meow token in the upcoming airdrop!":
        "$Meow token in the upcoming airdrop!",
      "Sign up to the campaign": "Sign up to the campaign",
      "Earn Meow for participating in community tasks!":
        "Earn Meow for participating in community tasks!",
      "Refer your friends": "Refer your friends",
      "You will both receive 1500 Meow once they complete sign up tasks and reach 2000 Zealy points!":
        "You will both receive 1500 Meow once they complete sign up tasks and reach 2000 Zealy points!",
      "Select communities will be able to claim a Meow bonus!":
        "Select communities will be able to claim a Meow bonus!",
      "Meow PASS": "Meow PASS",
      "Holders will receive a generous token airdrop, and you can earn Meow on top to maximise your claim!":
        "Holders will receive a generous token airdrop, and you can earn Meow on top to maximise your claim!",
      "What are IDO Meow POTIONS?": "What are IDO Meow POTIONS?",
      "Meow Potions multiply your $Meow token during the upcoming Token Presale.":
        "Meow Potions multiply your $Meow token during the upcoming Token Presale.",
      "Potions can be earned by climbing the Meow leaderboard, the more you earn, the better the multiplier!":
        "Potions can be earned by climbing the Meow leaderboard, the more you earn, the better the multiplier!",
      Airdrop: "Airdrop",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.":
        "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.",
      "Audits": "Audits",
      "Join Fair Launch on Pinksale": "Join Fair Launch on Pinksale",
      "": "",
      "": "",
      "": "",
    },
  },
  Chinese: {
    translation: {
      "Get $Meow": "获得$泡沫",
      "© Copyright 2024 - All Rights Reserved - Ding Dang":
        "© 版权所有 2024 - 保留所有权利 - 叮当",
      "Ding dang is not another meme token, it thrives on genuine utility":
        "Ding dang 不是另一个模因代币，它依靠真正的实用性而蓬勃发展",
      "A Symbiotic Harmony of Memes, Gaming and Staking Wealth":
        "模因、游戏和质押财富的共生和谐",
      "Stake $Meow": "赌注$泡沫",
      Whitepaper: "白皮书",
      "Join us": "加入我们",
      Add: "添加",
      "How to get $Meow": "如何获得$泡沫",
      "Create a MetaMask Wallet": "创建 元掩码 钱包",
      "$Meow token is available on the Binance smart chain. MetaMask is a third party BEP20 (BSC) browser wallet, and the very best at that! On Google Chrome, visit metamask.io to download the extension and set up a wallet. On mobile? Get":
        "$Meow 代币可在以太坊区块链上使用。 MetaMask 是第三方 ERC20（以太坊）浏览器钱包，而且是最好的！ 在 Google Chrome 上，访问 metamask.io 下载扩展程序并设置钱包。 在移动？ 得到",
      MetaMask: "元掩码",
      "s app for": "的应用程序",
      or: "或者",
      Android: "安卓",
      "STEP 1": "步骤1",
      "STEP 2": "步骤2",
      "STEP 3": "步骤3",
      "STEP 4": "步骤4",
      "Send $BNB to MetaMask": "发送 $BNB 到 MetaMask",
      "Acquire BNB through MetaMask itself or transfer it to your MetaMask wallet address from another wallet (e.g. Coinbase or Binance).":
        "通过 MetaMask 本身获取以太坊或从其他钱包（例如 Coinbase 或 Binance）将其转移到您的 MetaMask 钱包地址。",
      "Visit PancakeSwap": "访问 PancakeSwap",
      "You can currently swap BNB and other tokens for $Meow on PancakeSwap":
        "您目前可以在 PancakeSwap 上将 BNB 和其他代币兑换成 $Meow",
      "Swap $BNB for $Meow": "将 $BNB 换成 $Meow",
      "Enter the amount of $BNB you would like to swap for $Meow. Click Connect Wallet then Swap":
        "输入您想要兑换成 $buble 的 $BNB 金额。 单击“连接钱包”，然后单击“交换”",
      Why: "为什么",
      "Why you might want to consider investing in $Meow token":
        "为什么您可能要考虑投资 $Meow 代币",
      "Kikswap raised 506 BNB on presale launch in January 2022 with the marketcap over multi million dollar. Kik token price shoot over 10x on Pancakeswap listing. After 2 year team is back highly motivated with back up funds introducing you Ding Dang $Meow token. This is not just another meme token $Meow is a meme staking token introduced by KikSwap OG team. Stake any meme token and earn upto 1200% realistic APY in $Meow tokens.":
        "Kikswap 在 2022 年 1 月启动预售时筹集了 506 BNB，市值超过数百万美元。 在 Pancakeswap 上市时，Kik 代币价格飙升了 10 倍以上。 2 年后，团队带着备用资金积极回归，向您介绍 Ding Dang $Meow 代币。 这不仅仅是另一个 meme 代币 $Meow 是 KikSwap OG 团队推出的 meme 质押代币。 质押任何 Meme 代币，即可赚取高达 1200% 的 $Meow 代币实际 APY。",
      "Partner with us": "与我们合作",
      "Ding Dang NFTs": "叮当NFT",
      "Own a Unique Ding Dang NFTs": "拥有独特的叮当NFT",
      "Claim your exclusive slice of the Ding Dang with Meow NFTs":
        "通过泡泡 NFT 领取您的专属 Ding Dang 份额",
      "Crafted with care by our team and the vibrant community, these NFTs within the Ding Dang encompass a diverse range, including characters, props, and valuable items. For newcomers, we extend a warm welcome with a complimentary starter pack, allowing you to dive right in, play, and commence your journey to earning in the Jungle Twist universe!":
        "Ding Dang 中的这些 NFT 由我们的团队和充满活力的社区精心制作，涵盖了各种各样的内容，包括角色、道具和有价值的物品。 对于新玩家，我们向您提供免费的入门包，以热烈欢迎您，让您可以直接投入到《Jungle Twist》宇宙中，尽情玩耍，并开始您的赚钱之旅！",
      "View on Opensea": "在 Opensea 上查看",
      Partners: "伙伴",
      "DingDang PARTNERS": "叮当合伙人",
      "Where to": "去哪儿",
      "get $Meow": "得到$泡沫",
      "The $Meow token will be available on 20+ different exchanges. We continuously look for new partners to join us in offering you new ways to acquire $Meow.":
        "$Meow 代币将在 20 多个不同的交易所上市。。 我们不断寻找新的合作伙伴加入我们，为您提供获取美元泡沫的新方式。",
      $Meow: "$泡沫",
      "Ding dang Presents": "叮当礼物",
      "Jungle Twist": "丛林扭转",
      "In a cottage lived a curious cat named Dingdang. Dingdang unlike any other cat in the neighborhood, for she had a peculiar fascination with Meows.":
        "一间小屋里住着一只好奇的猫，名叫叮当。 叮当与附近的其他猫不同，因为她对泡泡有一种特殊的迷恋。",
      "Dingdang was captivated by Meows. She would chase them around the room, her nimble paws batting at the shimmering orbs, her tail swaying in delight. The more Meows she popped, the more she giggled, creating a whimsical symphony that filled the cottage.":
        "叮当被泡泡迷住了。 她会在房间里追逐它们，她灵活的爪子拍打着闪闪发光的球体，她的尾巴高兴地摇摆着。 她爆破的泡泡越多，咯咯笑得越多，在小屋里创造出一首异想天开的交响乐。",
      "Dingdang's love for Meows became legendary in the neighborhood. She continued to chase them with the same enchantment, bringing smiles and laughter wherever she went. And in the hearts of those who knew her, Dingdang became a reminder that sometimes, the simplest of joys can create the most magical moments in life, all you need to find happiness is a little Meow of joy.":
        "叮当对泡泡的热爱在附近成了传奇。 她继续以同样的魅力追逐着他们，所到之处都带着笑容和欢笑。 而在认识她的人心中，叮当提醒人们，有时候，最简单的快乐也能创造出生命中最神奇的时刻，找到幸福所需的只是一点点快乐的泡沫。",
      "Accumulate $Meow tokens while enjoying your beloved game.":
        "在享受您喜爱的游戏的同时积累 $buble 代币。",
      "Ding dang Jungle Twist game collection lies a treasure trove of timeless and casual games that you've grown to cherish, all featuring CAT as the central character. These games within the collection form an interconnected on-chain system, enabling users to earn and utilize the same $Meow token seamlessly across every game, including all new additions.":
        "叮当丛林扭动游戏合集是一个永恒的休闲游戏宝库，您会逐渐珍惜这些游戏，所有游戏都以猫为中心角色。 该系列中的这些游戏形成了一个互连的链上系统，使用户能够在每个游戏（包括所有新添加的游戏）中无缝地赚取和使用相同的 $Meow 代币。",
      "What's more, the magic doesn't stop there. When you unlock a character or collectible as an NFT in one game, its counterpart automatically unlocks in all the other games within the collection, creating a thrilling and unified gaming experience like no other.":
        "更重要的是，魔法还不止于此。 当您在一款游戏中将角色或收藏品解锁为 NFT 时，该系列中的所有其他游戏中的对应角色或收藏品也会自动解锁，从而创造出无与伦比的激动人心且统一的游戏体验。",
      Dangverse: "唐诗",
      "Jungle Twist by Dingdong, delivering an unparalleled VR adventure.":
        "Dingdong 的 Jungle Twist，带来无与伦比的 VR 冒险。",
      "After the launch of our initial Jungle Twist series, our dedicated team will embark on the exciting journey of developing the DangVerse—a groundbreaking VR metaverse where the NFTs acquired by our Jungle Twist game players will transcend into vibrant, living entities.":
        "在我们推出最初的 Jungle Twist 系列后，我们的专业团队将踏上开发 DangVerse 的激动人心的旅程，这是一个突破性的 VR 元宇宙，我们的 Jungle Twist 游戏玩家获得的 NFT 将超越为充满活力的生命实体。",
      Stake: "赌注",
      "Staking $Meow": "质押$泡沫",
      "Start staking $Meow today to unlock rewards and amplify your voting influence.":
        "立即开始质押 $Meow，解锁奖励并扩大您的投票影响力。",
      "The option to stake $Meow for rewards is now accessible, offering early adopters a bonus reward multiplier for staking their $Meow before the official launch. By staking $Meow, you not only earn more $Meow but also gain access to exclusive in-game and community collectibles (NFTs). Furthermore, the more $Meow you stake, the greater your influence in shaping the project's future through enhanced voting power.":
        "现在可以选择质押 $Meow 以获得奖励，为早期采用者在正式发布之前质押 $Meow 提供奖金奖励乘数。 通过质押 $Meow，您不仅可以赚取更多 $Meow，还可以获得独家游戏内和社区收藏品 (NFT)。 此外，您投入的泡沫越多，您通过增强投票权塑造项目未来的影响力就越大。",
      Stats: "统计数据",
      "Tokenomics & Stats": "代币经济学与统计",
      "Ding Dang Token Allocation": "叮当代币分配",
      "Initial Liquidity Allocation for DEX": "DEX 的初始流动性分配",
      "Presale Tokens Allocation": "预售代币分配",
      "Staking Rewards": "质押奖励",
      "Advisory Board": "咨询委员会",
      Marketing: "营销",
      "Product Development & Team": "产品开发与团队",
      "Research & Development": "研究和发展",
      "Gaming Reward": "游戏奖励",
      "Airdrop & Bounty Campaign": "空投和赏金活动",
      "3 Tier CEX liquidity (Lbank, Bitmart, Hotbit)":
        "三级CEX流动性（Lbank、Bitmart、Hotbit）",
      "1st Tier CEX liquidity (Binance, Huobi, Kucoin)":
        "第一级 CEX 流动性（币安、火币、库币）",
      "Raised Funds Allocation": "募集资金分配情况",
      Liquidity: "流动性",
      "Resource Hiring": "资源招聘",
      "Game Engine For Creators": "为创作者打造的游戏引擎",
      "R&D Funds": "研发经费",
      "Strategic Partnerships": "战略合作伙伴关系",
      "Game Reward": "游戏奖励",
      Liftoff: "升空",
      Roadmap: "路线图",
      "Q4 2024": "2024 年第四年",
      "PHASE inprogress": "阶段进行中",
      Launch: "发射",
      "Ding Dang Branding & Logo Designing": "叮当品牌及标志设计",
      "Website Release 2.0": "网站发布2.0",
      "Whitepaper, Gitbook": "白皮书、Gitbook",
      "Game Development Alpha": "游戏开发阿尔法",
      Tokenomics: "代币经济学",
      "Governance platform": "治理平台",
      "Staking Platform": "质押平台",
      "Growth hacking": "增长黑客",
      "Private sale (vesting)": "私募（归属）",
      "DEX liquidity": "去中心化交易所流动性",
      "3 tier CEX listing (Lbank, Hotbit, Bitmart)":
        "三级CEX上市（Lbank、Hotbit、Bitmart）",
      "Multi-Chain bridge integration": "多链桥集成",
      "Bounty campaign": "赏金活动",
      "Q1. 2024": "Q1. 2024年",
      "PHASE Pending": "阶段待定",
      Growth: "生长",
      "Collaboration with Influencers, Content Creators":
        "与影响者、内容创作者合作",
      "Brand awareness campaign": "品牌宣传活动",
      "Contest competition": "竞赛竞赛",
      "Onboard Ambassadors": "机上大使",
      "Private investors (25% Vesting release)": "私人投资者（25% 归属释放）",
      "Onboard Advisors": "船上顾问",
      "Bounty reward distribution": "赏金奖励分配",
      "Integrate EVM compatible Chains": "集成EVM兼容链",
      "Q2. 2024": "Q2。 2024年",
      Expansion: "扩张",
      "Reward system for community": "社区奖励制度",
      "SDK for traditional games (beta)": "传统游戏SDK（测试版）",
      "Viral gaming contest": "病毒式游戏大赛",
      "1.5M+ Mobile Game Downloads": "超过 150 万次移动游戏下载",
      "CEX listing (Binance, Huobi, Kucoin)":
        "CEX listing (Binance, Huobi, Kucoin)",
      "Hiring Industry leaders": "聘请行业领袖",
      "Gaming Hackathon": "游戏黑客马拉松",
      "Dingdang Merch": "叮当商品",
      "Improve Game UX": "改善游戏用户体验",
      "Strategic Partnership": "战略合作伙伴关系",
      "Organise gaming events & meetups": "组织游戏活动和聚会",
      "Participate in Pocket Games Events": "参加 Pocket Games 活动",
      "Join Gaming Conferences": "加入游戏会议",
      "Collaboration with celebrities": "与名人合作",
      "Q4. 2024": "Q4。 2024年",
      Utility: "公用事业",
      "Partnership with Gaming Engine (Unity3D, Unreal)":
        "与游戏引擎（Unity3D、Unreal）合作",
      "Marketplace for content creators": "内容创作者的市场",
      "Convert blockchain currency to fiat currency":
        "将区块链货币转换为法定货币",
      "Leaderboard for winners": "获胜者排行榜",
      "On demand content dashboard": "点播内容仪表板",
      "Meow Futures Trading Bot": "泡沫期货交易机器人",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 36 hours after their initial launch.":
        "假设您必须在您最喜欢的模因币推出时对其进行多头或空头交易。 现在，借助 Discord 和 Telegram 上提供的 Meow Futures Trading Bot，您可以在任何链上资产首次启动后 36 小时内做多或做空。",
      "Compete tasks and get 1000 $Meow when you sign up!":
        "报名参加任务即可获得 1000 美元泡泡！",
      "Invite code (Optional)": "邀请码（可选）",
      "Enter invite code →": "输入邀请码 →",
      "Connect wallet to claim": "连接钱包领取",
      Connect: "连接",
      "Join Dingdang.io": "加入叮当io",
      Telegram: "电报",
      "and get a verified role": "并获得经过验证的角色",
      Join: "加入",
      Discord: "不和谐",
      "Complete tasks and claim": "完成任务并领取",
      Meow: "气泡",
      Claim: "宣称",
      "THE TOKEN POWERING THE MEMECONOMY": "为记忆经济提供动力的代币",
      "The governance and reward mechanism for the DingDang Ecosystem":
        "叮当生态系统的治理和奖励机制",
      "JOIN THE $Meow TOKEN AIRDROP": "加入 $BUBLE 代币空投",
      "How to earn Meow": "如何赚取泡沫",
      "$Meow token in the upcoming airdrop!": "即将空投的 $Meow 代币！",
      "Sign up to the campaign": "报名参加该活动",
      "Earn Meow for participating in community tasks!":
        "参与社区任务即可赚取泡泡",
      "Refer your friends": "推荐你的朋友",
      "You will both receive 1500 Meow once they complete sign up tasks and reach 2000 Zealy points!":
        "一旦完成注册任务并达到 2000 Zealy 积分，你们都将获得 1500 个泡泡！",
      "Select communities will be able to claim a Meow bonus!":
        "选定的社区将能够领取泡沫奖金！",
      "Meow PASS": "泡泡通行证",
      "Holders will receive a generous token airdrop, and you can earn Meow on top to maximise your claim!":
        "持有者将收到慷慨的代币空投，您还可以赚取泡沫以最大化您的索赔！",
      "What are IDO Meow POTIONS?": "什么是 IDO 泡泡药水？",
      "Meow Potions multiply your $Meow token during the upcoming Token Presale.":
        "Meow Potions 在即将到来的代币预售期间使您的 $Meow 代币倍增。",
      "Potions can be earned by climbing the Meow leaderboard, the more you earn, the better the multiplier!":
        "爬上泡泡排行榜即可获得药水，赚得越多，倍数就越高！",
      Airdrop: "空投",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Meow Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.":
        "假设您必须在您最喜欢的模因币推出时对其进行多头或空头交易。 现在，借助 Discord 和 Telegram 上提供的泡沫期货交易机器人，您可以在任何链上资产首次启动后 24 小时内做多或做空。",
      "Audits": "审计",
      "Join Fair Launch on Pinksale": "加入 Pinksale 公平发布会",
      "": "",
      "": "",
      "": "",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("locale") || "English",
  fallbackLng: ["English", "Chinese"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
