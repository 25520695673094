/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Tokenomics = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container-fluid tokeomics mb-b pb-5'>
      <div className='container'>
        <div className='section-heading'>
          <div className='accent-large'>{t('Stats')}</div>
          <h2 className='sec-title'>{t('Tokenomics & Stats')}</h2>
        </div>
        <div className='row mt-2'>
          <div className='col-md-5 m-auto'>
            <img
              src='assets/6.png'
              className='w-100'
              alt=''
            />
          </div>
          <div className='col-md-7 m-auto'>
            <div className='row token-bg'>
              <div className=' col-sm-3'>
                {/* <h3>{t('Ding Dang Token Allocation')}</h3> */}
             {/* <ul className="mt-4"> 
              <li><strong>25%</strong>&nbsp; {t('Initial Liquidity Allocation for DEX')}</li>
              <li><strong>15%</strong>&nbsp; {t('Presale Tokens Allocation')}</li>
              <li><strong>10%</strong>&nbsp; {t('Staking Rewards')}</li>
              <li><strong>5%</strong>&nbsp;&nbsp;  {t('Advisory Board')}</li>
              <li><strong>6%</strong>&nbsp; &nbsp;{t('Marketing')} </li>
              <li><strong>7.5%</strong>&nbsp; {t('Product Development & Team')} </li>
              <li><strong>3.5%</strong>&nbsp; {t('Research & Development')}</li>
              <li><strong>10%</strong>&nbsp; {t('Gaming Reward')}</li>
              <li><strong>4%</strong>&nbsp; {t('Airdrop & Bounty Campaign')}</li>
              <li><strong>6%</strong>&nbsp; {t('3 Tier CEX liquidity (Lbank, Bitmart, Hotbit)')}</li>
              <li><strong>8%</strong> &nbsp; {t('1st Tier CEX liquidity (Binance, Huobi, Kucoin)')}</li>
              
             </ul> */}

                {/* <h3 className="mt-5">2% Usage Reward</h3>
                <h5>Thanks to user transactions (DeFi wallets only!)</h5>

                <h3 className="mt-5">100 Quadrillion</h3>
                <h5>TOTAL SUPPLY</h5> */}
              </div>


              <div className='col-sm-6'>
                <h3>{t('Raised Funds Allocation')}</h3>
              <ul>
              <li ><strong>33.7%</strong> &nbsp; {t('Presale Tokens Allocation')}</li>
              <li className="mt-2"><strong>16.3%</strong> &nbsp; {t('Initial Liquidity Allocation for DEX')}</li>
              <li className="mt-2"><strong>10%</strong> &nbsp; {t('Staking Rewards')}</li>
              <li className="mt-2"><strong>5%</strong> &nbsp; {t('Airdrop & Bounty Campaign')} </li>
              <li className="mt-2"><strong>34.9%</strong> &nbsp; {t('Burnt')}</li>
             
            

              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
