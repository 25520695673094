/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Roadmap = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container-fluid roadmap'>
      <div className='container-fluid'>
        <div className='section-heading'>
          <div className='accent-large'>{t('Liftoff')}</div>
          <h2 className='sec-title'>{t('Roadmap')}</h2>
        </div>
        <div className='roadmap-warper'>
          <div className='phase-wrapper'>
            <div className='phase'>
              <div className='accent-small '>{t('Q4 2023')}</div>
            </div>
            <div className='phase-details'>
              <h6 className='phase-status in-progress'>{t('PHASE inprogress')}</h6>
              <h3 className='phase-name'>{t('Launch')}</h3>
              <div className='phase-activities'>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Ding Dang Branding & Logo Designing')} 
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Website Release 2.0')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Whitepaper, Gitbook')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Game Development Alpha')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Tokenomics')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Governance platform')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Staking Platform')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Growth hacking')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Strategic Partnerships')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Private sale (vesting)')}</p>
                </div>

                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('DEX liquidity')} </p>
                </div>

                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('3 tier CEX listing (Lbank, Hotbit, Bitmart)')} {" "}
                  </p>
                </div>

                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Multi-Chain bridge integration')}  {" "}
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Bounty campaign')}  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 2 */}

          <div className='phase-wrapper'>
            <div className='phase'>
              <div className='accent-small '>{t('Q1. 2024')} </div>
            </div>
            <div className='phase-details'>
              <h6 className='phase-status'>{t('PHASE Pending')}</h6>
              <h3 className='phase-name'>{t('Growth')}</h3>
              <div className='phase-activities'>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Collaboration with Influencers, Content Creators')} 
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Brand awareness campaign')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Contest competition')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Onboard Ambassadors')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Private investors (25% Vesting release)')}  
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Onboard Advisors')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Bounty reward distribution')}  
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Integrate EVM compatible Chains')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}

          <div className='phase-wrapper'>
            <div className='phase'>
              <div className='accent-small '>{t('Q2. 2024')} </div>
            </div>
            <div className='phase-details'>
              <h6 className='phase-status'>{t('PHASE Pending')}</h6>
              <h3 className='phase-name'>{t('Expansion')}</h3>
              <div className='phase-activities'>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Reward system for community')} 
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('SDK for traditional games (beta)')} 
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Viral gaming contest')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('Private investors (25% Vesting release)')}    
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('1.5M+ Mobile Game Downloads')}  
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>
                  {t('CEX listing (Binance, Huobi, Kucoin)')}   
                  </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Hiring Industry leaders')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Gaming Hackathon')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Dingdang Merch')}</p>
                </div>
              </div>
            </div>
          </div>

          {/* 4*/}

          <div className='phase-wrapper'>
            <div className='phase'>
              <div className='accent-small '>{t('')}Q3. 2024</div>
            </div>
            <div className='phase-details'>
              <h6 className='phase-status'>{t('PHASE Pending')}</h6>
              <h3 className='phase-name'>{t('Growth')}</h3>
              <div className='phase-activities'>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Improve Game UX')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Strategic Partnership')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Organise gaming events & meetups')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Private investors (25% Vesting release)')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Participate in Pocket Games Events')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Join Gaming Conferences')} </p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Collaboration with celebrities')} </p>
                </div>
             
              </div>
            </div>
          </div>
          {/* 5 */}

          <div className='phase-wrapper'>
            <div className='phase'>
              <div className='accent-small '>{t('Q4. 2024')}</div>
            </div>
            <div className='phase-details'>
              <h6 className='phase-status'>{t('PHASE Pending')}</h6>
              <h3 className='phase-name'>{t('Utility')}</h3>
              <div className='phase-activities'>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Partnership with Gaming Engine (Unity3D, Unreal)')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Marketplace for content creators')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Convert blockchain currency to fiat currency')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Leaderboard for winners')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('On demand content dashboard')}</p>
                </div>
                <div className='phase-activity-item'>
                  <img
                    src='assets/checkmark-green.svg'
                    loading='lazy'
                    alt=''
                    className='green-checkmark'
                  />
                  <p className='no-bottom-padding'>{t('Private investors (25% Vesting release)')}</p>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
