/** @format */

import React from "react";
import Hero from "../component/elements/Hero";
import Section1 from "../component/elements/Section1";
import Section2 from "../component/elements/Section2";
import Section3 from "../component/elements/Section3";
import HowToGet from "../component/elements/HowToGet";
import Partner from "../component/elements/Partner";
import Tokenomics from "../component/elements/Tokenomics";
import Roadmap from "../component/elements/Roadmap";
import Faqs from "../component/elements/Faqs";
import JoinUs from "../component/elements/JoinUs";
import Staking from "../component/elements/Staking";
import NftsSection from "../component/elements/NftsSection";
import Bot from "../component/elements/Bot";

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Bot />  */}
      <Section1 />
      <Section2 />
      <Section3 />
      <HowToGet />
      <NftsSection />
      <Partner />
      <Tokenomics />
      <Staking />
      <Roadmap />
      {/* <Faqs /> */}
      <JoinUs />
    </>
  );
};

export default Home;
