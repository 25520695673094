/** @format */

import React from "react";
import { useTranslation } from 'react-i18next';

const Section1 = () => {
  const [t,i18n] = useTranslation();

  return (
    <div className='container section1   '>
      {/* <div className='section-heading'>
        <div className='accent-large'>{t('$Meow')} </div>
        <h2 className='sec-title'>{t('Ding dang Presents')}</h2>
      </div> */}
      <div className='sec-box '>
        <div className='row mt-5 '>
          <div className='col-md-6  '>
            <img src='assets/2.png' className='w-100 m-auto d-block ' alt='' />
          </div>
          <div className='col-md-6 m-auto'>
            <h3>{t('Jungle Twist')} </h3>
            <p>
            {t('In a cottage lived a curious cat named Dingdang. Dingdang unlike any other cat in the neighborhood, for she had a peculiar fascination with Meows.')} 
            </p>

            <p>
            {t('Dingdang was captivated by Meows. She would chase them around the room, her nimble paws batting at the shimmering orbs, her tail swaying in delight. The more Meows she popped, the more she giggled, creating a whimsical symphony that filled the cottage.')} 
            </p>
            <p>
            {t("Dingdang's love for Meows became legendary in the neighborhood. She continued to chase them with the same enchantment, bringing smiles and laughter wherever she went. And in the hearts of those who knew her, Dingdang became a reminder that sometimes, the simplest of joys can create the most magical moments in life, all you need to find happiness is a little Meow of joy.")}    
            </p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Section1;
