/** @format */

import React from "react";
import { useTranslation } from "react-i18next";

const JoinUs = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className='container-fluid w-100 join-us mt-5 pt-5 '>
      <div className='container'>
        <div className='section-heading mb-0 pb-0'>
          <div className='accent-large'>{t("Why")}</div>
        </div>
        <div className='row'>
          <div className='col-md-5'>
            <img src='assets/coin.png' className='w-100 relative' alt='' />
          </div>
          <div className='col-md-7 m-auto'>
            <h6 className='sec-h6 text-start '>
              {t("Why you might want to consider investing in $Meow token")}
            </h6>
            <p className='mt-5'>
              {t(
                "Kikswap raised 506 BNB on presale launch in January 2022 with the marketcap over multi million dollar. Kik token price shoot over 10x on Pancakeswap listing. After 2 year team is back highly motivated with back up funds introducing you Ding Dang $Meow token. This is not just another meme token $Meow is a meme staking token introduced by KikSwap OG team. Stake any meme token and earn upto 1200% realistic APY in $Meow tokens.",
              )}
            </p>
            <div className='hero-btn'>
              <a
                href='https://t.me/Dingdang_io'
                rel='noreferrer'
                target='_BLANK'
                className='btn-style b'>
                {t("Join us")}
              </a>{" "}
              &nbsp;
              <a
                href='https://t.me/Dingdang_io'
                rel='noreferrer'
                target='_BLANK'
                className='btn-style red-button '>
                {t("Partner with us")}
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
